import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import enLocale from 'antd/lib/locale-provider/en_GB';
import trLocale from 'antd/lib/locale-provider/tr_TR';
import moment from 'moment';
import 'react-bootstrap/';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'moment/locale/tr';
import CookieConsent from '../components/StaticPage/CookieConsent';
import { Dashboard } from '../layouts';
import ScrolltoTop from '../shared/scrollToTop';
import messages from './messages';
import { Private, Public, LoginLayout, Forbidden as ForbiddenLayout } from '../layouts/index';
import { createBrowserHistory } from 'history';

//Main page routes
const Main = lazy(() => import('../pages/Main'));
const NotFound = lazy(() => import('../pages/NotFound/index'));
const Forbidden = lazy(() => import('../pages/Forbidden/index'));
const RestCallClose = lazy(() => import('../pages/RestCallClose/index'));
const Error = lazy(() => import('../pages/Error/index'));

const Projects = lazy(() => import('../pages/Project/Projects'));

//Project Routes
const ProjectDetail = lazy(() => import('../pages/Project/ProjectDetail'));
const CreateProject = lazy(() => import('../components/header/create-project/index'));
const ProjectWizard = lazy(() => import('../components/projectCreate/ProjectWizard/index'));
const ProjectPreview = lazy(() => import('../pages/Project/ProjectPreview'));
const FilePreview = lazy(() => import('../components/custom/FileDownload/FilePreview'));

//User Routes
const Login = lazy(() => import('../components/auth/Login'));
const Signup = lazy(() => import('../components/auth/Signup'));
const ForgetPassword = lazy(() => import('../components/auth/ForgetPassword'));
const ResetPassword = lazy(() => import('../components/auth/ResetPassword'));
const Verify = lazy(() => import('../components/auth/Verify'));
const MailConfirm = lazy(() => import('../components/auth/MailConfirm'));
const LinkExpired = lazy(() => import('../components/auth/LinkExpired'));

//Investment Routes
const Investment = lazy(() => import('../pages/Investment'));

//Dashboard Routes
const Profile = lazy(() => import('../pages/Dashboard/Profile/index'));
const Messages = lazy(() => import('../pages/Dashboard/Messages/index'));
const DirectMessage = lazy(() => import('../pages/Dashboard/Messages/DirectMessage/index'));
const Portfolio = lazy(() => import('../pages/Dashboard/Portfolio/index'));
const Favorites = lazy(() => import('../pages/Dashboard/Favorites/index'));
const UserProjects = lazy(() => import('../pages/Dashboard/UserProjects/index'));
const UserIncomeStatement = lazy(() => import('../pages/Dashboard/UserIncomeStatements/index'));
const UserCorporateQualificationStatement = lazy(() =>
  import('../pages/Dashboard/UserCorporateQualificationStatements/index')
);
const Tables = lazy(() => import('../components/dashboard/Tables'));
const DashboardWelcome = lazy(() => import('../components/dashboard/DashboardWelcome/index'));
const ProjectUpdates = lazy(() => import('../components/dashboard/ProjectUpdates'));
const ChangeRequest = lazy(() => import('../components/dashboard/ChangeRequest'));

const IdentityVerificationRedirector = lazy(() =>
  import('../components/dashboard/Identity/identityverificationredirector')
);
const EDevletVerified = lazy(() => import('../components/Verification/EDevletVerified'));
const CreditCardPayment = lazy(() => import('../pages/Payment/CreditCardPayment'));
const CreditCardPaymentResult = lazy(() => import('../pages/Payment/CreditCardPaymentResult'));
const CookiePolicy = lazy(() => import('../components/StaticPage/CookiePolicy'));
const PrivacyPolicy = lazy(() => import('../components/StaticPage/PrivacyPolicy'));
const About = lazy(() => import('../components/StaticPage/About'));
const Services = lazy(() => import('../components/StaticPage/Services'));
const Contact = lazy(() => import('../components/StaticPage/Contact'));
const Announcements = lazy(() => import('../components/StaticPage/Announcements'));
const Operations = lazy(() => import('../components/StaticPage/Operations'));
const InformationSociety = lazy(() => import('../components/StaticPage/InformationSociety'));
const LegalInfo = lazy(() => import('../components/StaticPage/LegalInfo'));
const FrequentlyAskedQuestions = lazy(() =>
  import('../components/StaticPage/FrequentlyAskedQuestions')
);
const SuggestionsComplaints = lazy(() => import('../components/StaticPage/SuggestionsComplaints'));
const CompanyProfile = lazy(() => import('../components/StaticPage/CompanyProfile'));
const TransactionBrokerage = lazy(() => import('../components/StaticPage/TransactionBrokerage'));
const InvestmentCommittee = lazy(() => import('../components/StaticPage/InvestmentCommittee'));

const InformationSecurityPolicy = lazy(() =>
  import('../components/StaticPage/InformationSecurityPolicy')
);
const ConflictOfInterestPolicy = lazy(() =>
  import('../components/StaticPage/ConflictOfInterestPolicy')
);
const EvaluationPolicy = lazy(() => import('../components/StaticPage/EvaluationPolicy'));
const HumanResourcesPolicy = lazy(() => import('../components/StaticPage/HumanResourcesPolicy'));
const AntiBriberyAntiCorruptionPolicy = lazy(() =>
  import('../components/StaticPage/AntiBriberyAntiCorruptionPolicy')
);
const PreventionOfTerroristFinancingPolicy = lazy(() =>
  import('../components/StaticPage/PreventionOfTerroristFinancingPolicy')
);
const QualityPolicy = lazy(() => import('../components/StaticPage/QualityPolicy'));
const UserAgreement = lazy(() => import('../components/StaticPage/UserAgreement'));
const EntrepreneurAgreement = lazy(() => import('../components/StaticPage/EntrepreneurAgreement'));
const ConfidentialityPolicy = lazy(() => import('../components/StaticPage/ConfidentialityPolicy'));
const RiskNotification = lazy(() => import('../components/StaticPage/RiskNotification'));
const FeesAndDeductions = lazy(() => import('../components/StaticPage/FeesAndDeductions'));
const ProjectPublishingNotificationForm = lazy(() =>
  import('../components/StaticPage/ProjectPublishingNotificationForm')
);
const Scholarship = lazy(() => import('../components/StaticPage/Scholarship'));

const Blog = lazy(() => import('../components/StaticPage/Blog'));
const BlogDetail = lazy(() => import('../components/StaticPage/Blog/BlogDetail'));

const Press = lazy(() => import('../components/StaticPage/Press'));

const PressDetail = lazy(() => import('../components/StaticPage/Press/PressDetail'));

const history = createBrowserHistory();

moment.locale('tr');
const AppRoutes = (props) => {
  const CustomRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          return !!Layout ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Component {...props} />
          );
        }}
      />
    );
  };

  const hasProjectId = (props) => {
    return !!props?.location?.state?.projectId;
  };

  const getMKKLandingRequestId = (props) => {
    const requestId =
      !!props?.location?.search &&
      !!new URLSearchParams(props.location.search) &&
      !!new URLSearchParams(props.location.search).get('requestId')
        ? new URLSearchParams(props.location.search).get('requestId')
        : null;

    return requestId;
  };

  return (
    <ConfigProvider locale={localStorage['langDefault'] === 'EN' ? enLocale : trLocale}>
      <IntlProvider locale="en" messages={messages['en']}>
        <BrowserRouter>
          <ScrolltoTop>
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <CustomRoute
                  exact={true}
                  path="/"
                  layout={Public}
                  history={history}
                  component={(props) => {
                    return !!getMKKLandingRequestId(props) ? (
                      <Redirect {...props} to={{ ...props.location, pathname: '/mkklanding' }} />
                    ) : (
                      <Main {...props} />
                    );
                  }}
                />
                <CustomRoute path="/login" layout={LoginLayout} component={Login} />
                <CustomRoute path="/signup" layout={LoginLayout} component={Signup} />
                <CustomRoute path="/about" layout={Public} component={About} />
                <CustomRoute path="/services" layout={Public} component={Services} />
                <CustomRoute path="/cookie-policy" layout={Public} component={CookiePolicy} />
                <CustomRoute path="/privacy-policy" layout={Public} component={PrivacyPolicy} />
                <CustomRoute path="/contact" layout={Public} component={Contact} />
                <CustomRoute path="/announcements" layout={Public} component={Announcements} />
                <CustomRoute path="/operations" layout={Public} component={Operations} />
                <CustomRoute
                  path="/information-society"
                  layout={Public}
                  component={InformationSociety}
                />
                <CustomRoute path="/legal-info" layout={Public} component={LegalInfo} />
                <CustomRoute path="/faq" layout={Public} component={FrequentlyAskedQuestions} />
                <CustomRoute
                  path="/suggestions-and-complaints"
                  layout={Public}
                  component={SuggestionsComplaints}
                />
                <CustomRoute path="/company-profile" layout={Public} component={CompanyProfile} />
                <CustomRoute
                  path="/transaction-brokerage"
                  layout={Public}
                  component={TransactionBrokerage}
                />
                <CustomRoute
                  path="/investment-committee"
                  layout={Public}
                  component={InvestmentCommittee}
                />
                <CustomRoute
                  path="/information-security-policy"
                  layout={Public}
                  component={InformationSecurityPolicy}
                />
                <CustomRoute
                  path="/conflict-of-interest-policy"
                  layout={Public}
                  component={ConflictOfInterestPolicy}
                />
                <CustomRoute
                  path="/evaluation-policy"
                  layout={Public}
                  component={EvaluationPolicy}
                />
                <CustomRoute
                  path="/human-resources-policy"
                  layout={Public}
                  component={HumanResourcesPolicy}
                />
                <CustomRoute
                  path="/antibribery-anticorruption-policy"
                  layout={Public}
                  component={AntiBriberyAntiCorruptionPolicy}
                />
                <CustomRoute
                  path="/prevention-of-terrorist-financing-policy"
                  layout={Public}
                  component={PreventionOfTerroristFinancingPolicy}
                />
                <CustomRoute path="/quality-policy" layout={Public} component={QualityPolicy} />
                <CustomRoute path="/user-agreement" layout={Public} component={UserAgreement} />
                <CustomRoute
                  path="/entrepreneur-agreement"
                  layout={Public}
                  component={EntrepreneurAgreement}
                />
                <CustomRoute
                  path="/confidentiality-information"
                  layout={Public}
                  component={ConfidentialityPolicy}
                />
                <CustomRoute
                  path="/risk-notification"
                  layout={Public}
                  component={RiskNotification}
                />
                <CustomRoute
                  path="/fees-and-deductions"
                  layout={Public}
                  component={FeesAndDeductions}
                />
                <CustomRoute
                  path="/project-publishing-notification-form"
                  layout={Public}
                  component={ProjectPublishingNotificationForm}
                />
                <CustomRoute path="/scholarship" layout={Public} component={Scholarship} />
                <CustomRoute path="/press" layout={Public} component={Press} />
                <CustomRoute path="/pressDetail/:id" layout={Public} component={PressDetail} />

                <CustomRoute path="/blog" layout={Public} component={Blog} />
                <CustomRoute path="/blogDetail/:id" layout={Public} component={BlogDetail} />
                <CustomRoute
                  path="/forgetpassword"
                  layout={LoginLayout}
                  component={ForgetPassword}
                />
                <CustomRoute
                  path="/resetpassword/ticket=:token"
                  layout={Public}
                  component={ResetPassword}
                />
                <CustomRoute path="/mail-activation" layout={Public} component={MailConfirm} />
                <CustomRoute
                  path="/verify"
                  search="?token=:tokenKey"
                  layout={Public}
                  component={Verify}
                />
                <CustomRoute
                  path="/resolve"
                  search="?token=:tokenKey"
                  layout={Public}
                  component={Verify}
                />
                <CustomRoute
                  path="/linkexpired/token=:tokenKey"
                  layout={Public}
                  component={LinkExpired}
                />
                <CustomRoute
                  path="/projects"
                  search="category=:category"
                  layout={Public}
                  component={Projects}
                />
                <CustomRoute path="/project/preview" layout={Public} component={ProjectPreview} />
                <CustomRoute path="/project/:id" layout={Public} component={ProjectDetail} />
                <CustomRoute search="?files=:file" path="/files" component={FilePreview} />
                <CustomRoute path="/createProject" layout={Private} component={CreateProject} />
                <CustomRoute
                  path="/project-wizard/:type"
                  layout={Private}
                  component={ProjectWizard}
                />
                <CustomRoute
                  exact={true}
                  path="/investment"
                  layout={Private}
                  component={(props) => {
                    return !!hasProjectId(props) ? (
                      <Investment {...props} />
                    ) : (
                      <Redirect {...props} to={{ pathname: '/projects' }} />
                    );
                  }}
                />
                <CustomRoute
                  path="/investment/:something"
                  ProjectPreview
                  layout={Private}
                  component={(props) => <Redirect {...props} to={{ pathname: '/investment' }} />}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/profile"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <Profile {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/messages"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <Messages {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/messages/directmessage"
                  layout={Private}
                  component={(props) => {
                    return !!props.location &&
                      !!props.location.state &&
                      (!!props.location.state.projectOwnerUniqueId ||
                        !!props.location.state.threadUniqueId) ? (
                      <Dashboard>
                        <DirectMessage {...props} />
                      </Dashboard>
                    ) : (
                      <Redirect {...props} to={{ pathname: '/dashboard/messages' }} />
                    );
                  }}
                />
                <CustomRoute
                  path="/dashboard/messages/directmessage/:something"
                  layout={Private}
                  component={(props) => (
                    <Redirect {...props} to={{ pathname: '/dashboard/messages' }} />
                  )}
                />
                <CustomRoute
                  path="/dashboard/portfolio"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <Portfolio {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  path="/dashboard/favorites"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <Favorites {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  path="/dashboard/incomestatement"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <UserIncomeStatement {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  path="/dashboard/corporateQualificationStatement"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <UserCorporateQualificationStatement {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/projects"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <UserProjects {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/project/tables"
                  layout={Private}
                  component={(props) => {
                    return !!hasProjectId(props) ? (
                      <Dashboard>
                        <Tables {...props} />
                      </Dashboard>
                    ) : (
                      <Redirect {...props} to={{ pathname: '/dashboard/projects' }} />
                    );
                  }}
                />
                <CustomRoute
                  path="/dashboard/project/tables/:something"
                  layout={Private}
                  component={(props) => (
                    <Redirect {...props} to={{ pathname: '/dashboard/projects' }} />
                  )}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/project/updates"
                  layout={Private}
                  component={(props) => {
                    return !!hasProjectId(props) ? (
                      <Dashboard>
                        <ProjectUpdates {...props} />
                      </Dashboard>
                    ) : (
                      <Redirect {...props} to={{ pathname: '/dashboard/projects' }} />
                    );
                  }}
                />
                <CustomRoute
                  path="/dashboard/project/updates/:something"
                  layout={Private}
                  component={(props) => (
                    <Redirect {...props} to={{ pathname: '/dashboard/projects' }} />
                  )}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/project/changerequest"
                  layout={Private}
                  component={(props) => {
                    return !!hasProjectId(props) ? (
                      <Dashboard>
                        <ChangeRequest {...props} />
                      </Dashboard>
                    ) : (
                      <Redirect {...props} to={{ pathname: '/dashboard/projects' }} />
                    );
                  }}
                />
                <CustomRoute
                  path="/dashboard/project/changerequest/:something"
                  layout={Private}
                  component={(props) => (
                    <Redirect {...props} to={{ pathname: '/dashboard/projects' }} />
                  )}
                />
                <CustomRoute
                  exact={true}
                  path="/dashboard/"
                  layout={Private}
                  component={(props) => {
                    return (
                      <Dashboard>
                        <DashboardWelcome {...props} />
                      </Dashboard>
                    );
                  }}
                />
                <CustomRoute
                  search="?reference=:reference"
                  path="/identityverificationredirector"
                  component={IdentityVerificationRedirector}
                />
                <CustomRoute
                  path="/mkklanding"
                  layout={Public}
                  component={(props) => {
                    return <EDevletVerified {...props} />;
                  }}
                />
                <CustomRoute
                  search="?paymentId=:paymentId"
                  path="/creditcardpayment"
                  component={CreditCardPayment}
                />
                <CustomRoute
                  search="?paymentId=:paymentId"
                  path="/creditcardpaymentresult"
                  layout={Private}
                  component={CreditCardPaymentResult}
                />
                <CustomRoute
                  path="/rest-call-close"
                  layout={ForbiddenLayout}
                  component={(props) => {
                    return <RestCallClose {...props} />;
                  }}
                />
                <CustomRoute
                  path="/forbidden"
                  layout={ForbiddenLayout}
                  component={(props) => {
                    return <Forbidden {...props} />;
                  }}
                />
                <CustomRoute
                  path="/error"
                  layout={Public}
                  component={(props) => {
                    return <Error {...props} />;
                  }}
                />
                <CustomRoute path="/notfound" layout={Public} component={NotFound} />
                <CustomRoute layout={Public} component={NotFound} />
              </Switch>
            </Suspense>
          </ScrolltoTop>
        </BrowserRouter>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default AppRoutes;
